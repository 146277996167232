<template>
    <div class="animated fadeIn">
        <CRow>
            <CCol lg="12">
                
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="8">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="cog"/>  Manage Document API
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                            <template v-if="file_api.status == 'Active'">
                                <CButton 
                                    size="sm"
                                    color="danger" 
                                    class="float-right" 
                                    @click="toggleEdit(), returnInformation()" 
                                    v-show="edit"
                                >
                                    <font-awesome-icon icon="window-close"/> Cancel
                                </CButton>

                                <CButton 
                                    :disabled="!isValid || !hasMadeChanges"
                                    v-if="$store.getters['can']('update-document-template-file-api')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    v-show="edit" 
                                    @click="updateFileAPI()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>

                                <CButton 
                                    v-if="$store.getters['can']('edit-document-template-file-api')"
                                    size="sm"
                                    color="info" 
                                    class="float-right" 
                                    @click="toggleEdit" 
                                    v-show="!edit"
                                >
                                    <font-awesome-icon icon="edit"/> Edit
                                </CButton>
                            </template>
                            <template v-if="file_api.status == 'Archived'">
                                <CButton 
                                    v-if="$store.getters['can']('restore-document-template')"
                                    size="sm"
                                    color="success" 
                                    class="float-right" 
                                    @click="restore()"
                                >
                                <font-awesome-icon icon="trash-restore"/> Restore
                                </CButton>
                            </template>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="12">
                                <h5>Status: <CBadge :color="getColorStatus(file_api.status)">{{file_api.status}}</CBadge></h5> 
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="10">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.name.$model"
                                    :isValid="checkIfValid($v.file_api, 'name')"
                                    label="Name" 
                                    type="text" 
                                    placeholder="JO Routing Document API" 
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-nospecialcharacter
                                    v-model="file_api.name"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Must be more than five(5) characters."
                                    
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="10">
                                <label>Method</label>
                                <v-select 
                                    :disabled="disable == 1 ? true : false"
                                    label="name"
                                    :options="methods_type"
                                    v-model="file_api.method"
                                    :class="!disable ? !file_api.method ? 'has-error' : 'has-success' : ''"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CInput
                                    :lazy="false"
                                    :value.sync="$v.file_api.api.$model"
                                    :isValid="checkIfValid($v.file_api, 'api')"
                                    label="URL" 
                                    type="text" 
                                    placeholder="https://test.po-routing.com.ph" 
                                    autocomplete="off"  
                                    v-model="file_api.api"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Invalid URL"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                               <CTextarea
                                    :lazy="false"
                                    :value.sync="$v.file_api.description.$model"
                                    :isValid="checkIfValid($v.file_api, 'description')"
                                    class="grp-text-area"
                                    label="Description"
                                    placeholder="Content..."
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="file_api.description"
                                    :disabled="disable == 1 ? true : false"
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="link"/>  API Parameters
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                        <!-- option for basic parameter and field-based parameter -->
                        <!-- <div class="float-right" v-if="edit && is_old_data">
                            <h4>
                            <p-radio 
                                class="p-icon p-curve p-jelly small-radio" 
                                name="APIParameter" 
                                color="success" 
                                value="add_old_fields" 
                                v-model="selectedOption"
                            >
                                Add Basic Parameter
                                <font-awesome-icon class="icon" icon="check"/>
                            </p-radio>  

                            <p-radio 
                                class="p-icon p-curve p-jelly small-radio" 
                                name="APIParameter" 
                                value="add_parameter_from_field" 
                                color="success"  
                                v-model="selectedOption"
                            >
                                Add Field-Based Parameter 
                                <font-awesome-icon class="icon" icon="check"/>
                            </p-radio>
                            </h4>
                        </div> -->
                    </CCardHeader>
                    <CCardBody>
                        <template v-if="!disable">
                            <CRow>
                                <CCol lg="3">
                                    <!-- <label>{{is_old_data ? (is_enabled_field_form ? 'Field Type' : 'Type') : 'Field Type'}}</label> -->
                                    <label>Field Type</label>
                                </CCol>
                                <CCol lg="3">
                                    <!-- <label>{{is_old_data ? (is_enabled_field_form ? 'Field Custom Name' : 'Parameter') : 'Field Custom Name'}}</label> -->
                                    <label>Field Custom Name</label>
                                </CCol>
                                <CCol lg="2">
                                    <label>Reference number</label>
                                </CCol>
                                <CCol lg="2">
                                    <label>Required</label>
                                </CCol>
                                <CCol lg="2">
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="3">
                                    <!-- <div v-if="is_old_data">
                                        <div v-if="is_enabled_field_form">
                                            <CRow>
                                                <v-select
                                                    label="display_name"
                                                    :options="fields"
                                                    v-model="field.field_id"
                                                    :class="!field.field_id ? 'has-error' : 'has-success'"
                                                    style="width: 80%; margin-right: 5px; padding-left: 5%;"
                                                >
                                                    <template #option="{ display_name, field_type, parameter_name }">
                                                        <h6 style="margin: 0">{{ display_name }}</h6>
                                                        Type: <em>{{ field_type.name }}</em> <br>
                                                        Parameter: <em>{{ parameter_name }}</em>
                                                    </template>
                                                </v-select>
                                                <CButton
                                                    class="float-right"
                                                    color="primary"
                                                    title="Add new field"
                                                    @click="modal_new_field = true"
                                                >
                                                    <font-awesome-icon icon="plus"/>
                                                </CButton>
                                            </CRow>
                                        </div>
                                        <div v-else>
                                            <CRow>
                                                <v-select
                                                    label="name"
                                                    :options="fields_type"
                                                    v-model="field.type"
                                                    :class="!field.type ? 'has-error' : 'has-success'"
                                                    style="width: 80%; margin-right: 5px; padding-left: 5%;"
                                                />
                                            </CRow>

                                        </div>
                                    </div>
                                    <div v-else> -->
                                        <CRow>
                                            <v-select 
                                                label="display_name"
                                                :options="fields"
                                                v-model="field.field_id"
                                                :class="!field.field_id ? 'has-error' : 'has-success'"
                                                style="width: 80%; margin-right: 5px; padding-left: 5%;"
                                            >
                                                <template #option="{ display_name, field_type, parameter_name }">
                                                    <h6 style="margin: 0">{{ display_name }}</h6>
                                                    Type: <em>{{ field_type.name }}</em> <br> 
                                                    Parameter: <em>{{ parameter_name }}</em>
                                                </template>
                                            </v-select>
                                            <CButton 
                                                class="float-right"
                                                color="primary" 
                                                title="Add new field"
                                                @click="modal_new_field = true"
                                            >
                                                <font-awesome-icon icon="plus"/>
                                            </CButton>
                                        </CRow>
                                    <!-- </div> -->
                                </CCol>

                                <CCol lg="3">
                                    <!-- <div v-if="is_old_data">
                                        <div v-if="is_enabled_field_form">
                                            <CInput
                                                type="text"
                                                placeholder="JO Number"
                                                autocomplete="off"
                                                v-model="field.field_custom_name"
                                            />
                                        </div>
                                        <div v-else>
                                            <CInput
                                                type="text"
                                                placeholder="JO Number"
                                                autocomplete="off"
                                                v-model="field.parameter"
                                                :class="!field.parameter ? 'has-inp-error' : 'has-inp-success'"
                                            />
                                        </div>
                                    </div>
                                    <div v-else> -->
                                        <CInput
                                            type="text" 
                                            placeholder="JO Number" 
                                            autocomplete="off"  
                                            v-model="field.field_custom_name"
                                        />
                                    <!-- </div> -->
                                </CCol>

                                <CCol lg="2">
                                    <p-check 
                                        :disabled="file_api.document_template_file_api_params.some(function(item) { return item.is_reference})"
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        value="true"
                                        v-model="field.reference_number"
                                    >
                                        <font-awesome-icon class="icon" icon="check"/>
                                    </p-check>  
                                </CCol>
                                <CCol lg="2">
                                    <p-check 
                                        :disabled="field.reference_number ? true : false"
                                        class="p-icon p-bigger" 
                                        color="success" 
                                        value="true"
                                        v-model="field.required"
                                    >
                                        <font-awesome-icon class="icon" icon="check"/>
                                    </p-check>  
                                </CCol>
                                <CCol lg="2">
                                    <!-- <div v-if="is_old_data">
                                        <div v-if="is_enabled_field_form">
                                            <CButton
                                                :disabled="!field.field_id"
                                                color="primary"
                                                @click="addDynamicField()" >
                                                <font-awesome-icon icon="plus"/> Add Field
                                            </CButton>
                                        </div>
                                        <div v-else>
                                            <CButton
                                                :disabled="!field.parameter || !field.type"
                                                color="primary"
                                                @click="addDynamicField()" >
                                                <font-awesome-icon icon="plus"/> Add Field
                                            </CButton>
                                        </div>
                                    </div>
                                    <div v-else> -->
                                        <CButton 
                                            :disabled="!field.field_id"
                                            color="primary" 
                                            @click="addDynamicField()" >
                                            <font-awesome-icon icon="plus"/> Add Field
                                        </CButton>
                                    <!-- </div> -->
                                </CCol>
                            </CRow>
                        </template>
                        <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                            <CTab>
                                <template slot="title">
                                    Active API/s Parameters  <CBadge color="success">( {{ [...new Set(file_api_param_active.map((item) => item.id))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="file_api_param_active"
                                            :fields="parameters_field"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            hover
                                        >
                                        
                                            <template #field_custom_name="{item}">
                                                <td v-if="!edit">
                                                    {{item.field_custom_name}}
                                                </td>
                                                <td v-else>
                                                    <CInput
                                                        type="text" 
                                                        autocomplete="off"  
                                                        :value="item.field_custom_name"
                                                        v-model="item.field_custom_name"
                                                    />
                                                </td>
                                            </template>
        
                                            <template #parameter="{item}">
                                                <td v-if="!edit">
                                                    {{item.parameter}}
                                                </td>
                                                <td v-else>
                                                    <CInput
                                                        type="text" 
                                                        autocomplete="off"  
                                                        :value="item.parameter"
                                                        @input="val => item.parameter = val === '' ? null : val"
                                                        :class="!item.parameter ? (is_old_data ? (!item.field ? 'has-inp-error' : '') : 'has-inp-error') : 'has-inp-success'"
                                                    />
                                                </td>
                                            </template>
                                            
                                            <template #type="{item}">
                                                <td v-if="!edit">
                                                    {{ item.type }}
                                                </td>
                                                <td v-else>
                                                    <v-select 
                                                        label="name"
                                                        :options="fields_type"
                                                        v-model="item.type"
                                                        @input="handleTypeInput(item)"
                                                        :class="!item.type ? (is_old_data ? (!item.field ? 'has-error' : '') : 'has-error') : 'has-success'"
                                                    />
                                                </td>
                                            </template>
        
                                            <template #field="{item}">
                                                <td v-if="!edit">
                                                    <h6 style="margin: 0">{{ item.field_id ? item.field.display_name : '' }}</h6>
                                                    <em>
                                                        {{ item.field_id ? ('Type: ' + (fields.find(field => field.id == item.field.id)?.field_type.name || '')) : '' }}
                                                    </em>
                                                    <br>
                                                    <em>
                                                        {{ item.field_id ? ('Parameter: ' + item.field.parameter_name) : '' }}
                                                    </em>
                                                </td>
                                                <td v-else>
                                                    <v-select
                                                        label="display_name"
                                                        :options="fields"
                                                        v-model="item.field"
                                                        @input="handleFieldInput(item)"
                                                        :class="!item.field ? (is_old_data ? (!item.type || !item.parameter ? 'has-error' : '') : 'has-error') : 'has-success'"
                                                        style="width: 80%; margin-right: 5px;"
                                                    >
                                                        <template #option="{ display_name, field_type, parameter_name }">
                                                            <h6 style="margin: 0">{{ display_name }}</h6>
                                                            Type: <em>{{ field_type.name }}</em> <br> Parameter: <em>{{ parameter_name }}</em>
                                                        </template>
                                                    </v-select> 
                                                </td>
                                            </template>

                                            <template #is_reference="{item}">
                                                <td  v-if="!edit">
                                                    <CBadge :color="getColorStatus(item.is_reference == '1' ? 'Yes' : 'No')">
                                                        {{item.is_reference == '1' ? 'Yes' : 'No'}}
                                                    </CBadge>
                                                </td>
                                                <td v-else>
                                                    <p-radio
                                                        :disabled="replacing_ref_num || item.recently_added ? true : false"
                                                        class="p-icon p-curve p-bigger"
                                                        name="icon_solid"
                                                        color="success"
                                                        :value="item.id"
                                                        @change="changeReference(item)"
                                                        v-model="selected_reference">
                                                        <font-awesome-icon class="icon" icon="check"/>
                                                    </p-radio>
                                                </td>
                                            </template>
                                            <template #is_required="{item}">
                                                <td  v-if="!edit">
                                                    <CBadge :color="getColorStatus(item.is_required == '1' ? 'Yes' : 'No')">
                                                        {{item.is_required == '1' ? 'Yes' : 'No'}}
                                                    </CBadge>
                                                </td>
                                                <td v-else>
                                                    <p-check
                                                        :disabled="item.is_reference ? true : false"
                                                        class="p-icon p-bigger"
                                                        color="success"
                                                        value="true"
                                                        v-model="item.is_required"
                                                    >
                                                        <font-awesome-icon class="icon" icon="check"/>
                                                    </p-check>
                                                </td>
                                            </template>
                                            <template #action="{item, index}">
                                                <td>
                                                    <CButton
                                                    v-if="item.recently_added == null"
                                                    shape="pill"
                                                    size="sm"
                                                    color="dark"
                                                    @click="archiveParameter(item, index)"
                                                    :disabled = "replacing_ref_num ? true : false"
                                                    >
                                                    <font-awesome-icon :icon="replacing_ref_num ? 'circle-notch' : 'minus-square'"
                                                    :pulse="replacing_ref_num ? true : false"/> Archived
                                                    </CButton>
                                                    <CButton
                                                        @click="removeParameter(item)"
                                                        color="danger"
                                                        shape="pill"
                                                        size="sm"
                                                        v-else
                                                    >
                                                        <font-awesome-icon icon="times"/>
                                                    </CButton>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                            <CTab>
                                <template slot="title">
                                    Inactive API/s Parameters  <CBadge color="danger">( {{ [...new Set(file_api_param_inactive.map((item) => item.id))].length }} )</CBadge>
                                </template>
                                <CRow>
                                    <CCol lg="12">
                                        <CDataTable
                                            :items="file_api_param_inactive"
                                            :fields="inactive_parameters_field"
                                            :items-per-page="10"
                                            border
                                            items-per-page-select
                                            sorter
                                            pagination
                                            hover
                                        >

                                            <template #field_custom_name="{item}">
                                                <td>
                                                    {{item.field_custom_name}}
                                                </td>
                                            </template>

                                            <template #parameter="{item}">
                                                <td>
                                                    {{item.parameter}}
                                                </td>
                                            </template>

                                            <template #type="{item}">
                                                <td>
                                                    {{ item.type }}
                                                </td>
                                            </template>

                                            <template #field="{item}">
                                                <td>
                                                    <h6 style="margin: 0">{{ item.field_id ? item.field.display_name : '' }}</h6>
                                                    <em>
                                                        {{ item.field_id ? ('Type: ' + (fields.find(field => field.id == item.field.id)?.field_type.name || '')) : '' }}
                                                    </em>
                                                    <br>
                                                    <em>
                                                        {{ item.field_id ? ('Parameter: ' + item.field.parameter_name) : '' }}
                                                    </em>
                                                </td>
                                            </template>

                                            <template #is_reference="{item}">
                                                <td>
                                                    <CBadge :color="getColorStatus(item.is_reference == '1' ? 'Yes' : 'No')">
                                                        {{item.is_reference == '1' ? 'Yes' : 'No'}}
                                                    </CBadge>
                                                </td>
                                            </template>
                                            <template #is_required="{item}">
                                                <td>
                                                    <CBadge :color="getColorStatus(item.is_required == '1' ? 'Yes' : 'No')">
                                                        {{item.is_required == '1' ? 'Yes' : 'No'}}
                                                    </CBadge>
                                                </td>
                                            </template>
                                            <!-- <template #action="{item}"> -->
                                            <template #action="{item, index}">
                                                <td>
                                                    <template v-if="$store.getters['can']('restore-document-template')">
                                                        <CButton
                                                            size="sm"
                                                            shape="pill"
                                                            color="success"
                                                            :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                            @click="restoreParameter(item)"
                                                        >

                                                            <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'"
                                                                :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                        </CButton>
                                                    </template>
                                                    <template v-else>
                                                        <i><b>No permission to restore.</b></i>
                                                    </template>
                                                </td>
                                            </template>
                                        </CDataTable>
                                    </CCol>
                                </CRow>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow v-show="edit">
            <CCol lg="12">
                <CCard borderColor="danger" v-if="$store.getters['can']('archive-prefix')">
                    <CCardHeader>
                        <font-awesome-icon icon="exclamation" :style="{ color: 'red' }"/>
                                Danger Zone
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="8">
                                <h6>Archive this Document File API?</h6>
                            </CCol>
                            <CCol lg="12">
                                <label> Once archived, you cannot use this Document File API anymore. Please be certain.</label>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                <CButton shape="pill" color="warning" @click="archive()" >
                                    <font-awesome-icon icon="minus-square" /> Archived
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CModal
            color="primary"
            :show.sync="modal_new_field"
            centered
            size="xl"
        >
            <template #header>
                <h5><font-awesome-icon icon="pencil-alt" /> Create New Field</h5>
                <CButton
                    class="pull-right"
                    color="light"
                    shape="pill"
                    size="sm"
                    @click="modal_new_field = false"
                >
                    <font-awesome-icon icon="times" />
                </CButton>
            </template>
            <template #body-wrapper>
                <CRow>
                    <CCol lg="12">
                        <CCardBody>
                            <a-popover title="NOTE:" placement="left" >
                                <template slot="content">
                                    <FieldHelp/>
                                </template>
                                <font-awesome-icon
                                    style="color: #4caf50; float: right;"
                                    icon="question-circle"
                                    size="lg"
                                />
                            </a-popover>
                            <br>
                            <br>
                            <FieldCreateForm ref="fieldCreateForm"/>
                        </CCardBody>
                    </CCol>
                </CRow>
            </template>
            <template #footer>
                <hr hide>
            </template>
        </CModal>
    </div>
</template>
<style>
    .ant-popover {
        z-index: 9999;
    }
    .small-radio {
        font-size: 19px;
        transform: scale(0.8); 
    }
</style>
<script>
import { required,minLength, url } from "vuelidate/lib/validators";
import FieldCreateForm from '../../form-management/field/components/FieldCreateForm.vue';
import FieldHelp from '../../form-management/components/FieldHelp.vue';

export default {
    name: 'DocumentAPIEdit',
    components:{
        FieldCreateForm,
        FieldHelp,
    },
    data() {
        return {
            fields_type: [
                {name: "input"},
                {name: "textarea"},
            ],
            restoring_id: null,
            restoring:false,

            replacing_ref_num:false,

            methods_type: [
                { name: 'GET' },
                { name: 'POST' },
            ],

            parameter_input: [],
            type_input: [],
            reference_number_input: [],
            required_input: [],

            is_enabled_field_form:false,

            field: {
                parameter: null,
                type: null,
                field_custom_name: null,
                field: null,
                reference_number: false,
                required: false,
            },

            file_api: {
                id: null,
                name: null,
                api: null,
                description: null,
                document_template_file_api_params: [],
            },

            file_api_backup : {
                id: null,
                name: null,
                api: null,
                description: null,
                document_template_file_api_params: []
            },

            selected_reference: null,
            selected_reference_backup: null,

            recently_removed_param_id: [],

            selected_required_ids: [],

            disable: 1,
            edit: false,

            reference_param_id:null,

            document_template_file_api_params_backup: null,
            fields : [],
            modal_new_field: false,
            is_old_data:false,
            my_tab:0,
            apiParamCount:0,
            // selectedOption: 'add_old_fields',
            inactive_is_old_data:false,
        }
    },
    validations: {
        file_api: {
            name: { required, minLength: minLength(5) },
            method: { required, },
            api: { required, url },
            description: { required, minLength: minLength(5) },
            document_template_file_api_params: { required, minLength: minLength(1) }
        },
    },
    computed: {
        isValid () { return !this.$v.file_api.$invalid },
        isDirty () { return this.$v.file_api.$anyDirty },

        hasMadeChanges: function() {
            return JSON.stringify(this.file_api_backup) == JSON.stringify(this.file_api) ? false : true;
        },

        file_api_param_inactive: function() {
            return this.file_api.document_template_file_api_params.filter((item) => item.deleted_at != null);
        },

        file_api_param_active: function() {
            return this.file_api.document_template_file_api_params.filter((item) => item.deleted_at == null);

        },

        parameters_field: function() {
            let custom_field = [
                { key:  this.is_old_data ? 'type' : 'field', sorter: false, label: this.is_old_data ? 'Type' : 'Field', },
                { key: this.is_old_data ? 'parameter' : 'field_custom_name', sorter: false, label: this.is_old_data ? 'Parameter' : 'Field Custom Name',},
                { key: 'is_reference', label: 'Reference Number', sorter: false, },
                { key: 'is_required', label: 'Required', sorter: false, },
            ]
            if(this.is_old_data){
                custom_field.splice(2,0,
                    {key: 'field', sorter: false, label:'Field'},
                    {key: 'field_custom_name', sorter: false, label: 'Field Custom Name'},
                );
            }

            if(!this.disable) {
                custom_field.push({ key: 'action' })
            }
            return custom_field;
        },
        inactive_parameters_field: function() {
            let custom_field = [
                { key:  this.inactive_is_old_data ? 'type' : 'field', sorter: false, label: this.inactive_is_old_data ? 'Type' : 'Field', },
                { key: this.inactive_is_old_data ? 'parameter' : 'field_custom_name', sorter: false, label: this.inactive_is_old_data ? 'Parameter' : 'Field Custom Name',},
                { key: 'is_reference', label: 'Reference Number', sorter: false, },
                { key: 'is_required', label: 'Required', sorter: false, },
            ]
            if(this.inactive_is_old_data){
                custom_field.splice(2,0,
                    {key: 'field', sorter: false, label:'Field'},
                    {key: 'field_custom_name', sorter: false, label: 'Field Custom Name'},
                );
            }

            if(!this.disable) {
                custom_field.push({ key: 'action' })
            }
            return custom_field;
        }
    },
    created() {
        this.file_api.id = this.$route.params.id
        this.getFields();
        this.getFileAPI();
    },
    methods: {
        onload : function () {
            this.file_api = []
            this.file_api_backup = []
        },
        removeParameter: function (item) {
            this.file_api.document_template_file_api_params.filter((object, ind) => {
                // if(this.is_old_data){
                //     if(item.field && object.field && item.field.display_name == object.field.display_name) {
                //         this.file_api.document_template_file_api_params.splice(ind, 1);
                //     } else if(!item.field && item.parameter == object.parameter && item.type == object.type) {
                //         this.file_api.document_template_file_api_params.splice(ind, 1);
                //     }
                // }else{
                    if(item.field && object.field && item.field.display_name == object.field.display_name && item.field.id == object.field.id) {
                        this.file_api.document_template_file_api_params.splice(ind, 1)
                    }
                // }
            })
        },
        archiveParameter: function (item, index) {
            let parameterName = item.field_id
            ? (item.field_custom_name ? item.field_custom_name : item.field.display_name)
            : item.parameter;

            this.apiParamCount = this.file_api.document_template_file_api_params.filter(param => {
                return param.deleted_at == null;
            }).length;

            if(this.apiParamCount == 1){
                this.$swal('Action denied','At least one API parameter is required.','warning');
                return;
            }
            if(this.reference_param_id == item.id){
                this.$swal('Action denied',`This parameter is a reference number and can't be archived.`,'warning');
                return;
            }

            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to remove the <b>${parameterName}</b> File API.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    return axios.post('/drs/document-template-file-api/archive-api-param/' + item.id,
                    {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${parameterName}</b> Successfully archived.`,
                            })
                            this.file_api.document_template_file_api_params.splice(index, 1)
                            this.$Progress.finish()
                            this.getFileAPI();

                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        addDynamicField: function () {
            // if(this.is_old_data && !this.is_enabled_field_form){
            //     if(!this.field.parameter || !this.field.type) {
            //         return this.showErrorAlert(`Please fill up required fields.`);
            //     }
            // }else{
                if(!this.field.field_id) {
                    return this.showErrorAlert(`Please fill up required fields.`);
                }
            // }

            let row = {
                id: Math.floor(Math.random() * 100) + Math.floor(Math.random() * 100) + 1,
                document_template_file_api_id: this.file_api.id,
                parameter: this.field.parameter,
                type: this.field.type,
                field_custom_name: this.field.field_custom_name,
                field: this.field.field_id,
                is_reference: this.field.reference_number,
                is_required: this.field.required,
                recently_added: true,
            }

            for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                // if(this.is_old_data && !this.is_enabled_field_form){
                //     if(this.file_api.document_template_file_api_params[i].parameter == row.parameter && this.file_api.document_template_file_api_params[i].type == row.type.name) {
                //         return this.showErrorAlert(`${row.parameter} already exists.`);
                //     }
                // }else{
                    if(this.file_api.document_template_file_api_params[i].field_id == row.field.id) {
                        return this.showErrorAlert(`${row.field.display_name} already exists.`);
                    }
                // }
            }

            if(this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference}) && row.is_reference) {
                return this.showErrorAlert(`Multiple reference number is not allowed.`);
            }

            if(row.is_reference) {
                this.selected_reference = row.id
            }

            // Refresh the array //
            const all_params = this.file_api.document_template_file_api_params;
            this.file_api.document_template_file_api_params = []
            all_params.push(row);
            this.file_api.document_template_file_api_params = all_params;

            this.field = this.cleaFieldObject();
        },
        cleaFieldObject: function () {
            return {
                field_custom_name: null,
                field_id: null,
                type:null,
                parameter:null,
                reference_number: false,
                required: false,
            }
        },
        getFileAPI: function () {
            this.$Progress.start()
            axios.get('/drs/document-template-file-api/' + this.file_api.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    let data = response.data.data;
                    let activeParams = data.document_template_file_api_params.filter(param => param.deleted_at === null);


                    if(data.document_template_file_api_params[0].field_id === null){
                        this.assignData(response.data.data);
                        let converted = activeParams.every(item => {
                            return item.field_id != null && item.deleted_at == null;
                        });
                        converted ? this.is_old_data = false : this.is_old_data = true
                        
                        this.inactive_is_old_data = true;
                    }else{
                        this.is_old_data = false;
                        this.inactive_is_old_data = false;

                        for(let i = 0; i < data.document_template_file_api_params.length; i++) {
                            let row = data.document_template_file_api_params[i];
                            let field_data = this.fields.find(x => x.id == row.field.id);
                            if (field_data) {
                                row.field = field_data;
                            }  
    
                            row.is_required = row.is_required === "1" ? true : false;
                            row.is_reference = row.is_reference === "1" ? true : false;
    
                            this.selected_required_ids = [];
                            if(row.is_reference){
                                this.selected_reference = row.id
                                this.selected_reference_backup = row.id
                            } 
                            if(row.is_required) {
                                this.selected_required_ids.push(row.id);
                            }
                        }
                    }
                    this.file_api = data;
                    this.reference_param_id =  this.file_api.document_template_file_api_params
                    .find(param => param.is_reference === true)?.id;
                    this.file_api.status = data.deleted_at ? 'Archived' : 'Active';
                    this.file_api_backup = JSON.parse(JSON.stringify(data));
                    this.$Progress.finish();
                }
            })
        },
        assignData: function (data) {
            this.selected_required_ids = []
            for (let row of data.document_template_file_api_params){
                row.is_required = row.is_required === "1" ? true : false;
                row.is_reference = row.is_reference === "1" ? true : false;
                if(row.is_reference){
                    this.selected_reference = row.id
                    this.selected_reference_backup = row.id
                }

                if(row.is_required) {
                    this.selected_required_ids.push(row.id)
                }
            }

            this.file_api = data;

            this.file_api_backup = JSON.parse(JSON.stringify(data))
            this.$Progress.finish()
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) {
                this.$v.$touch()
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            this.file_api = JSON.parse(JSON.stringify(this.file_api_backup))
            this.selected_reference = this.selected_reference_backup
            this.recently_removed_params = []


            this.edit_parameters = false;
            this.disable_parameters = 1;
            this.document_template_file_api_params_backup = null
        },
        validateFileAPI:function () {
            if( !this.file_api.name || !this.file_api.method || !this.file_api.api || !this.file_api.description || this.file_api.document_template_file_api_params.length <= 0 ){
                return false;
            }
            return true;
        },
        changeReference: function (item) {
            this.$Progress.start()
            this.replacing_ref_num = true;
            axios.post('/drs/document-template-file-api/replace-api-ref-number/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$Progress.finish()
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `Reference number replaced successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.reference_param_id = item.id;
                    this.replacing_ref_num = false;
                    this.getFileAPI();
                }
            })
        },
        updateFileAPI: function () {
            if(JSON.stringify(this.file_api_backup) == JSON.stringify(this.file_api)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }

            // if(this.is_old_data){
            //     if(this.file_api.document_template_file_api_params.some(function(item) { return (!item.parameter || !item.type) && !item.field})) {
            //         return this.showErrorAlert(`Field(s) cannot be empty.`);
            //     }

            //     if(!this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference })) {
            //         return this.showErrorAlert(`Please select reference number from the parameters.`);
            //     }

            //     if(this.file_api.document_template_file_api_params.some(function(ele) { return (!ele.parameter || !ele.type) && !ele.field })) {
            //         return this.showErrorAlert(`Field(s) Invalid.`);
            //     }

            //     if(this.file_api.document_template_file_api_params.some(function(item) { return (item.parameter || item.type) && item.field})) {
            //         return this.showErrorAlert(`Can't have values in both Field and Parameter/Type.`);
            //     }

            // }else{
                if(this.file_api.document_template_file_api_params.some(function(item) { return (!item.parameter || !item.type) && !item.field})) {
                    return this.showErrorAlert(`Field(s) cannot be empty.`);
                }

                if(!this.file_api.document_template_file_api_params.some(function(item) { return item.is_reference })) {
                    return this.showErrorAlert(`Please select reference number from the parameters.`);
                }

                if(this.file_api.document_template_file_api_params.some(function(ele) { return (!ele.parameter || !ele.type) && !ele.field})) {
                    return this.showErrorAlert(`Field(s) Invalid.`);
                }
            // }

            if(this.validateFileAPI()){
                this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to update the Document File API.",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => {


                        //Data manipulation
                        for (let i = 0; i < this.file_api.document_template_file_api_params.length; i++) {
                            const element = this.file_api.document_template_file_api_params[i];
                            // if(this.is_old_data){
                                
                            //     if(!element.field && element.type && element.type.name) {
                            //         let selected_type = element.type.name;
                            //         element.type = selected_type;
                            //     } 
                                
                            //     if(element.field && element.field.name){
                            //         let selected_type_field_name = element.field_id.id;
                            //         element.field_id = selected_type_field_name;
                            //     }else if (!element.field){
                            //         element.field_id = null;

                            //     }

                            // }else{
                                if(element.field && element.field.name){
                                    let selected_type = element.field_id.id
                                    element.field_id = selected_type
                                }
                            // }

                            if(element.recently_added) {
                                delete element.recently_added;
                                element.id = null
                            }
                        }

                        let object = this.file_api;
                        if(this.recently_removed_param_id.length > 0) {
                            object.recently_removed_param_id = this.recently_removed_param_id;
                        }

                        if(this.file_api.method.name) {
                            this.file_api.method = this.file_api.method.name
                        }


                        this.$Progress.start()
                        return axios.post('/drs/document-template-file-api/update/' + object.id, object, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    text: `${this.file_api.name} has been updated.`,
                                })

                                this.getFileAPI();
                                this.toggleEdit()
                                this.$Progress.finish()

                                this.recently_removed_param_id = []
                            }
                        })
                    }
                }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                    }
                })
                return;
            }
            this.$swal({
                toast: true,
                position: 'top-right',
                timer: 3000,
                icon: 'error',
                title: 'Please fill up required fields.',
                showConfirmButton: false,
                timerProgressBar: true,
            })
            return;
        },
        async getFields () {
            this.$Progress.start()
            await axios.get('/form-managements/field/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    if (response.status === 200) {
                        this.fields = response.data.data.filter(field => field.id != 1 && field.display_name != 'Aggregate Function' && field.field_type_id != 7);

                    }
                    this.$Progress.finish()
                }
            })
        },
        showErrorAlert(message) {
            return this.$swal({
                toast: true,
                position: 'top-right',
                showConfirmButton: false,
                timer: 3000,
                icon: 'error',
                title: message,
                timerProgressBar: true,
            });
        },
        archive: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to remove the <b>${this.file_api.name}</b> File API.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => {
                    this.$Progress.start()
                    return axios.post('/drs/document-template-file-api/archive/' + this.file_api.id, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.file_api.status = 'Archived';
                            this.file_api_backup.status = 'Archived'
                            this.toggleEdit()
                            this.$Progress.finish()
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.file_api.name}</b> Successfully archived.`,
                            })
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
            return;
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/drs/document-template-file-api/restore/' + this.file_api.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.file_api = JSON.parse(JSON.stringify(this.file_api_backup))
                    this.file_api.status = 'Active'
                    this.$Progress.finish()
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.file_api.name} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                }
            })
        },
        restoreParameter: function (item) {
            let parameterName = item.field_id
            ? (item.field_custom_name ? item.field_custom_name : item.field.display_name)
            : item.parameter;

            this.restoring = true;
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/drs/document-template-file-api/restore-api-param/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$Progress.finish()
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${parameterName} restored successfully.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.file_api.document_template_file_api_params.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish()
                    this.restoring = false;
                    this.getFileAPI();
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item
        },
        handleFieldInput(item) {
            if (item.field) {
                item.parameter = null;
                item.type = null;
            }
        },
        handleTypeInput(item) {
            if (item.type) {
                item.field = null;
            }
        },
    },
    watch: {
        'field.reference_number': function (value) {
            this.field.required = value ? true : false
        },
        modal_new_field: function (value) {
            if(!value) this.getFields();
        },
        // selectedOption(newVal) {
        //     if (newVal === 'add_old_fields') {
        //         this.is_enabled_field_form = false;
        //         this.add_old_fields = true;
        //     } else if (newVal === 'add_parameter_from_field') {
        //         this.is_enabled_field_form = true;
        //         this.add_old_fields = false;
        //     }
        // }

    },
}
</script>